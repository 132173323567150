<template>
  <section>
    <v-sheet
      color="white"
      elevation="5"
      outlined
      rounded
      shaped
      class="boxauthoriation"
    >
      <v-row>
        <v-col class="text-center">
          <router-link to="/">
            <img src="@/assets/necologo.png" />
          </router-link>
        </v-col>
        <v-col md="12">
          <h3 class="text-center titleform">
            Hire the best talent for your business
          </h3>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            class="form-section"
          >
            <v-row>
              <v-col md="12" cols="12">
                <v-text-field
                  v-model="fullname"
                  label="Name of the organization"
                  :rules="fullnameRule"
                  outlined
                  @keyup.enter.stop="validate"
                ></v-text-field>
              </v-col>
              <v-col md="12" cols="12">
                <v-text-field
                  v-model="email"
                  label="Email Address"
                  :rules="emailRules"
                  outlined
                  @keyup.enter.stop="validate"
                ></v-text-field>
              </v-col>
              <v-col md="12" cols="12">
                <v-text-field
                  v-model="phone"
                  label="Phone Number"
                  :rules="phoneRule"
                  outlined
                  @keyup.enter.stop="validate"
                ></v-text-field>
              </v-col>
              <v-col md="12" cols="12">
                <v-text-field
                  v-model="password"
                  label="Password"
                  outlined
                  :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required]"
                  :type="showpassword ? 'text' : 'password'"
                  @click:append="showpassword = !showpassword"
                  @keyup.enter.stop="validate"
                ></v-text-field>
              </v-col>
              <v-col md="12" cols="12">
                <v-checkbox
                  v-model="checkbox"
                  label="I Agree to Terms and Conditions & Privacy Policy governing the use of necojobs.com.np ."
                  :rules="checkboxRule"
                ></v-checkbox>
              </v-col>

              <v-col cols="12" class="submitbtn">
                <v-btn
                  :disabled="!valid"
                  class="submitbutton btn-dark"
                  @click="validate"
                  v-if="!allLoadingAuth"
                >
                  Register
                </v-btn>
                <v-progress-circular
                  v-if="allLoadingAuth"
                  indeterminate
                ></v-progress-circular>

                <div class="registerlink">
                  Already have an account ?<router-link to="/"
                    >Login</router-link
                  >
                </div>

                <v-snackbar
                  v-model="snackbar"
                  v-if="allRegister.data != undefined"
                >
                  {{ allRegister.data.message }}
                  <template v-slot:action="{ attrs }">
                    <v-btn
                      color="red"
                      text
                      v-bind="attrs"
                      @click="snackbar = false"
                    >
                      Close
                    </v-btn>
                  </template>
                </v-snackbar>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-sheet>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "RegisterCompany",
  computed: {
    ...mapGetters(["allLoadingAuth", "allRegister"]),
  },
  data: () => ({
    allUserloading: false,
    valid: true,
    email: "",
    password: "",
    fullname: "",
    showpassword: false,
    checkbox:false,
    snackbar: false,
    phone:"",
    emailRules: [(v) => !!v || "Email is required"],
    phoneRule: [(v) => !!v || "Phone Number is required"],
    rules: {
      required: (value) => !!value || "Password is required.",
      min: (v) => v.length >= 8 || "Min 8 characters",
    },
    fullnameRule: [(v) => !!v || "Fullname is required"],
    checkboxRule: [(v) => !!v || "You need to accept first"],
  }),
  methods: {
    ...mapActions(["register"]),
    validate() {
      if (this.$refs.form.validate()) {
        let userData = {
          fullname: this.fullname,
          email: this.email,
          password: this.password,
          phone: this.phone,
          role:'company'
        };
        this.register(userData);
        this.snackbar = true;
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    logout() {
      this.$store.dispatch("logoutUser");
    },
  },
  created() {
    if (localStorage.getItem("token") != undefined) {
      this.$router.push("/").catch((err) => {
        console.error(err);
      });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.boxauthoriation {
  padding: 30px;
  width: 450px;
  border-radius: 10px !important;
}
.registerlink {
  margin-top: 15px;
  a {
    margin-left: 5px;
    color: $tagcolor;
  }
}

.submitbtn {
  text-align: center;
}
img {
  width: 200px;
}
.form-section {
  .col-md-6,
  .col-md-12 {
    padding: 0 20px !important;
  }
}
.titleform {
  margin-bottom: 20px;
}
.registerlink {
  margin-top: 20px;
  font-size: 14px;
}
.btn-dark {
  background: $tagcolor !important;
  color: $white !important;
  width: 200px;
  height: 50px !important;
}
</style>
